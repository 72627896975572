import React from "react";
import "./styles.css";

const BankDetails = ({ data }) => {
  return (
    <>
      <div className="bankDetails-wrapper">
        <div className="head-text">
          <p className="text-start fw-bold fs-5">Bank Details</p>
        </div>
        <div className="text-start fw-semibold">
          <label htmlFor="nameInput">Name as per Bank</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="nameInput"
            value={data?.bankDetails?.bankRegisteredName}
          />
          <label htmlFor="bankNameInput">New Bank Name</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="bankNameInput"
            value={data?.bankDetails?.bankName}
          />
          <label htmlFor="ifscInput">New IFSC</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="ifscInput"
            value={data?.bankDetails?.ifsc}
          />
          <label htmlFor="accountNumberInput">New Bank Account Number</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="accountNumberInput"
            value={data?.bankDetails?.accountNumber}
          />
          <label htmlFor="accountNumberType">New Bank Account Type</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="accountNumberType"
            value={data?.bankDetails?.accountType}
          />
          <label htmlFor="micrCodeInput">New MICR Code</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="micrCodeInput"
            value={data?.bankDetails?.micr}
          />
          {/* <label htmlFor="branchInput">Branch</label>
        <input
          type="text"
          className="form-control" disabled
          id="branchInput"
          value={data?.bankDetails?.branch ?? ""}
        /> */}
          {/* <label htmlFor="bankAddressInput">Bank Address</label>
        <input
          type="text"
          className="form-control" disabled
          id="bankAddressInput"
          value={data?.bankDetails?.address}
        />
         */}
          {/* <label htmlFor="pennyDropInput">Payout id of payment</label>
        <input
          type="text"
          className="form-control" disabled
          id="pennyDropInput"
          value={data?.bankDetails?.payoutId}
        /> */}
          <label htmlFor="pennyDropReason">Penny Drop Verify</label>
          <input
            type="text"
            className="form-control"
            disabled
            id="micrCodeInput"
            value={data?.bankDetails?.pennyDropReason}
          />
        
           <label htmlFor="pennyReasonInput">Penny Drop Reason</label>
           <input
            type="text"
            className="form-control"
            disabled
            id="micrCodeInput"
            value={data?.bankDetails?.status}
          />
        </div>
      </div>
    </>
  );
};

export default BankDetails;
