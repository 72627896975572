import React from 'react'
import Logo from "../../assest/RYZ.png"
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigation =useNavigate()

  const LogOut =() =>{
    window.localStorage.removeItem("isLoggedIn");
    sessionStorage.clear();
    window.location.reload();
  }
  return (
    <div>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid d-flex">
          <img src={Logo} className="ryz-logo img-fluid" alt="..." width="100px" />
          <div className='d-flex flex-column align-items-center gap-3'>
          <Button className='btn btn-warning' variant="success" onClick={LogOut}>Sign Out</Button>
              <button
                onClick={() => navigation("/")}
                type="button"
                className="btn btn-primary bg-primary px-4" > back </button> </div>
        </div>
      </nav>
    </div>
  )
}

export {Navbar}
