import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PdfPreview from "../../components/pdfViewer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CiSearch } from "react-icons/ci";
import { FILTER_DATA } from "../../constant/MapperConstant";

const ClientListing = () => {
  const navigation = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [data, setData] = React.useState({ totalPage: 1 });
  const [showAadhar, setShowAadhar] = React.useState(false)
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = React.useState([0]);
  const [searchData, setSearchData] = React.useState({
    search: "",
    page: 1,
    filter: "REQUESTED",
  });
  const handleClose = () => setShowAadhar(false);
  const { loading, fetchData } = useApi();
  const [base64String, setBase64String] = React.useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [responseContent, setResponseContent] = useState(null);


  async function getClientList() {
    try {
      const data = await fetchData(
        `rekyc/users/details?search=${searchData.search}&page=${searchData.page}&rekycstatus=${searchData.filter}`,
        {},
        process.env.REACT_APP_ADMIN_BASE_URL
      );
      setData(data.pageDetail);
      // console.log(data.rekycUserBasicAdminContent)
      setFilteredData(data?.rekycUserBasicAdminContent ?? []);
    } catch (e) {
      toast.error("Error while fetching data");
    }
  }

  React.useEffect(() => {
    getClientList();
  }, [searchData.page, searchData.search, searchData.filter]);

  const getDataTime = (epoch) => {
    const date = new Date(+epoch);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const handleApiCalls = async (url, { }, BASE_URL, successMsg, errorMsg) => {
    const baseUrl = process.env.REACT_APP_ADMIN_BACKOFFICE_BASE_URL ?? BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      toast.success(successMsg);
      return data;
    } catch (e) {
      toast.error(errorMsg);
    }
  };

  const handleRedirectToDetails = (clientId, userid) => {
    navigation(`client/${userid}`, {
      state: { userid: userid },
    });
    console.log("==>", userid);
  };

  const handleFilter = (value) => {
    if (value === "ALL") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: "" }));
    } else {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim() ?? "";
    if (
      (e.key === "Enter" && searchValue) ||
      (!searchValue && searchData.search)
    ) {
      setSearchData((prev) => ({ ...prev, search: searchValue, page: 1 }));
    }
    e.preventDefault();
  };
  const handleBse = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/push/bse";

    setLoader(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`BSE ${responseData.status}`);
      } else {
        toast.error(`BSE ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };

  const handleNse = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/push/nse";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`NSE ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };
  const handleBackOffice = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/push/backoffice";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`BackOffice ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };
  const handleCdsl = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/push/cdsl";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`CDSL ${responseData.status}`);
      } else {
        toast.error(`CDSL ${responseData.message}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // No need to parse response.json() again here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // You can add a toast message for the error if needed
      // toast.error('Error fetching data:', error.message)
    } finally {
      setLoader(false);
    }
  };
  const CompleteButton = (user_id) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://kyc.finovo.tech:5556/api/v1/rekyc/stage/change/complete`;
    setLoader(true)
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
        xuserid: user_id,
      },
    })
      .then((response) => {
        if (!response.ok) {
          setLoader(false)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        setLoader(false)
        console.error("Error:", error);
      });
  };

  const handleGeneratePdf = async (userid_id) => {
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/generate/pdf/docs";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "xuserid": userid_id,
          "Authorization": storedToken,
        },
      });

      if (response.status === 400) {
        toast.error("E-Sign document id not available");
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Helper function to download files
      const downloadFile = (base64Data, fileName, mimeType) => {
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: mimeType });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

      if (data.pdf) {
        downloadFile(data.pdf, data.name.concat(" REKYC"), 'application/pdf');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  }

  const ExportButton = (userid, name) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://kyc.finovo.tech:5556/api/v1/rekyc/esigned/export/docs`;
    setLoader(true)
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
        xuserid: userid, // Assuming xuserid is the correct header for passing userId
      },
    })
      .then(response => {
        setLoader(false)
        if (response.status === 400) {
          toast.error("E-Sign document id not available");
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        // Helper function to download files
        const downloadFile = (base64Data, fileName, mimeType) => {
          let blob;
          if (mimeType === 'text/xml') {
            // Directly handle XML string without base64 decoding
            blob = new Blob([base64Data], { type: mimeType });
          } else {
            // For binary data like PDFs, assume base64 encoded and decode
            const binaryData = atob(base64Data);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
              uint8Array[i] = binaryData.charCodeAt(i);
            }
            blob = new Blob([uint8Array], { type: mimeType });
          }

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };

        // Assuming auditTrailPdf and esignedPdf are base64 encoded and aadharXml is a direct XML string
        if (data.auditTrailPdf) {
          downloadFile(data.auditTrailPdf, `${name} auditTrail`, 'application/pdf');
        }
        if (data.esignedPdf) {
          downloadFile(data.esignedPdf, `${name} esigned`, 'application/pdf');
        }
        // Check and download Aadhar XML if available
        if (data.aadharXml) {
          downloadFile(data.aadharXml, `${name} aadhar`, 'text/xml');
          console.log("======data xml ", data.aadharXml);
        }
      })
      .catch(error => {
        setLoader(false);
        console.error('Error:', error);
      });
  };

  const handleKRA = async (userid_id) => {
    // toast.success("KRA Successful");
    const storedToken = sessionStorage.getItem("authToken");
    const url = "https://kyc.finovo.tech:5556/api/v1/rekyc/push/kra/upload";

    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          xuserid: userid_id,
          Authorization: storedToken,
        },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(`KRA ${responseData.status}`);
      } else {
        toast.error("Failed to fetch data");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const FetchDataInfo = async () => {
    setLoader(true)
    try {
      const storedToken = sessionStorage.getItem('authToken');
      const response = await fetch('https://ekyc.finovo.tech:5556/api/v1/download/excel/editLogs', {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      // Download the response
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // Set response content if needed
      // const jsonData = await response.json();
      // setResponseContent(jsonData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false)
    }
  };

  const handleInfoDownload = () => {
    console.log("===========>");
    setShowInfoPopup(true);
  };

  const handleInfoDownloadAll = async () => {
    await fetchData();
  };

  const handleInfoSubmit = async () => {
    const fromEpoch = new Date(fromDate).getTime();
    const toEpoch = new Date(toDate).getTime();
    const storedToken = sessionStorage.getItem('authToken');
    const apiUrl = `https://ekyc.finovo.tech:5556/api/v1/download/excel/editLogs?from=${fromEpoch}&to=${toEpoch}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setShowInfoPopup(false);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const FetchData = async () => {
    setLoader(true)
    try {
      const storedToken = sessionStorage.getItem('authToken');
      const response = await fetch('https://ekyc.finovo.tech:5556/api/v1/download/excel/client-data-report', {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      // Download the response
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // Set response content if needed
      // const jsonData = await response.json();
      // setResponseContent(jsonData);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false)
    }
  };

  const handleDownload = () => {
    console.log("===========>");
    setShowPopup(true);
  };

  const handleDownloadAll = async () => {
    await fetchData();
  };

  const handleSubmit = async () => {
    const fromEpoch = new Date(fromDate).getTime();
    const toEpoch = new Date(toDate).getTime();
    const storedToken = sessionStorage.getItem('authToken');
    const apiUrl = `https://ekyc.finovo.tech:5556/api/v1/download/excel/client-data-report?from=${fromEpoch}&to=${toEpoch}`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': storedToken,
        }
      });
      const data = await response.blob();

      console.log('Response:', data);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client_data_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setShowPopup(false);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div>

      <Loader open={loading} />
      <Loader open={loader} />
      {base64String ? (
        <Modal
          size="lg"
          dialogClassName="modal-90w modal-90h"
          show={Boolean(base64String)}
          onHide={() => setBase64String("")}
        >
          <PdfPreview base64String={base64String} />
        </Modal>
      ) : (
        <></>
      )}
      <ToastContainer />
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-center gap-5 align-items-center">
            <div className="w-25 d-flex">
              <input
                onKeyUpCapture={handleSearch}
                className="w-100 py-2 rounded-pill rounded-end-0 border border-end-0"
                active="border-0"
                placeholder="Enter Mobile Number"
              />
              <div className="bg-white pt-1  rounded-pill rounded-start-0">
                <CiSearch className="fs-3 me-3" />
              </div>
            </div>
            <DropdownButton
              variant={searchData.filter ? "success" : "info"}
              className="my-3"
              id="dropdown-basic-button"
              title={searchData?.filter ? searchData?.filter : "Select Filter"}
            >
              {FILTER_DATA.map((item, idx) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handleFilter(item.id)}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="text-center bg-white">
                  <th>Action</th>
                  <th>PAN</th>
                  <th>RE KYC Status</th>
                  <th>Client Code</th>
                  <th>Customer Name</th>
                  <th>Mob No.</th>
                  {/* <th>Payment Status</th> */}
                  <th>Email Change</th>
                  <th>Mobile Change</th>
                  <th>Address Change</th>
                  <th>Bank Change</th>
                  <th>Date</th>
                  <th>Freeze/Unfreeze</th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete BackOffice
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete NSE
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete BSE
                  </th>
                  <th
                    className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                      } ${searchData.filter === "REQUESTED" ||
                        searchData.filter === "REJECTED" ||
                        searchData.filter === "COMPLETED" ||
                        searchData.filter === "ESIGN_REQUESTED"
                        ? "d-none"
                        : ""
                      }`}
                  >
                    Complete KRA
                  </th>
                  <th
                    className={`${searchData.filter === "REQUESTED" ||
                      searchData.filter === "REJECTED" ||
                      searchData.filter === "ESIGNED" ||
                      searchData.filter === "ESIGN_REQUESTED"
                      ? "d-block"
                      : ""
                      }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                  >
                    Complete
                  </th>
                  <th></th>
                  <th
                  // className={`${searchData.filter === "REQUESTED" ||
                  //   searchData.filter === "REJECTED" ||
                  //   searchData.filter === "ESIGNED" ||
                  //   searchData.filter === "ESIGN_REQUESTED"
                  //   ? "d-block"
                  //   : ""
                  //   }
                  //       ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                  //       `}
                  >
                    GeneratePdf
                  </th>
                  <th></th>
                  <th
                    className={` ${searchData.filter === "REQUESTED" ||
                      searchData.filter === "REJECTED" ||
                      searchData.filter === "ESIGNED" ||
                      searchData.filter === "ESIGN_REQUESTED"
                      ? "d-block"
                      : ""
                      }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                  >
                    Export
                  </th>
                </tr>
              </thead>
              {filteredData && filteredData.length ? (
                <tbody align="center">
                  {filteredData.map((row) => (
                    <tr key={row.mobileNo}>
                      <td>
                        <Button
                          onClick={() =>
                            handleRedirectToDetails(row.clientCode, row.userid)
                          }
                          variant="primary"
                        >
                          {"Verify"}
                        </Button>
                      </td>{" "}
                      <td>
                        {row?.rekycUserBasicAdminContent?.panNumber ?? "N/A"}
                      </td>

                      <td>{row?.rekycStatus}</td>
                      <td>{row?.clientCode ?? "N/A"}</td>
                      <td>{row?.name}</td>
                      <td>{row?.phoneNumber}</td>
                      <td>{row?.emailChanged ? "Yes" : "No"}</td>
                      <td>{row?.mobileChanged ? "Yes" : "No"}</td>
                      <td>{row?.addressChanged ? "Yes" : "No"}</td>
                      <td>{row?.bankChanged ? "Yes" : "No"}</td>
                      <td>{getDataTime(row?.updateDate)}</td>
                      <td>freeze</td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row?.userPushedDataInfo?.backofficepushed === true}
                          onClick={() => {
                            handleBackOffice(row.userid);
                          }}
                          // variant="success"
                          className={`${row?.userPushedDataInfo?.backofficepushed === true ? "bg-danger" : "bg-success"}`}
                        >
                          Complete BackOffice
                        </Button>
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row?.userPushedDataInfo?.nsepushed === true}
                          onClick={() => {
                            handleNse(row.userid);
                          }}
                          // variant="success"
                          className={`${row?.userPushedDataInfo?.nsepushed === true ? "bg-danger" : "bg-success"}`}
                        >
                          Complete NSE
                        </Button>
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row?.userPushedDataInfo?.bsepushed === true}
                          onClick={() => {
                            handleBse(row.userid);
                          }}
                          // variant="success"
                          className={`${row?.userPushedDataInfo?.bsepushed === true ? "bg-danger" : "bg-success"}`}
                        >
                          Complete BSE
                        </Button>
                        {load && <Loader open={load} />}
                      </td>
                      <td
                        className={`p-3${searchData.filter === "ESIGNED" ? "d-block p-3" : ""
                          } ${searchData.filter === "REQUESTED" ||
                            searchData.filter === "REJECTED" ||
                            searchData.filter === "COMPLETED" ||
                            searchData.filter === "ESIGN_REQUESTED"
                            ? "d-none"
                            : ""
                          }`}
                      >
                        <Button
                          disabled={row?.userPushedDataInfo?.krapushed === true}
                          onClick={() => {
                            handleKRA(row.userid);
                          }}
                          // variant="success"
                          className={`${row?.userPushedDataInfo?.krapushed === true ? "bg-danger" : "bg-success"}`}
                        >
                          Complete KRA
                        </Button>
                        {load && <Loader open={load} />}
                      </td>
                      <td
                        className={`p-3 ${searchData.filter === "REQUESTED" ||
                          searchData.filter === "REJECTED" ||
                          searchData.filter === "ESIGNED" ||
                          searchData.filter === "ESIGN_REQUESTED"
                          ? "d-block"
                          : ""
                          }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                      >
                        <Button
                          className="p-3"
                          disabled={row.clientCode === "NULL"}
                          onClick={() => CompleteButton(row.userid)}
                          variant="success"
                        >
                          Complete
                        </Button>
                      </td>
                      <td></td>
                      <td
                      // className={`p-3 ${searchData.filter === "REQUESTED" ||
                      //   searchData.filter === "REJECTED" ||
                      //   searchData.filter === "ESIGNED" ||
                      //   searchData.filter === "ESIGN_REQUESTED"
                      //   ? "d-block"
                      //   : ""
                      //   }
                      //   ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                      //   `}
                      >
                        <Button
                          disabled={row.rekycStatus === "true"}
                          onClick={() => handleGeneratePdf(row.userid)}
                          variant="success"
                        >
                          GeneratePdf
                        </Button>
                      </td>
                      <td></td>
                      <td
                        className={`p-3 ${searchData.filter === "REQUESTED" ||
                          searchData.filter === "REJECTED" ||
                          searchData.filter === "ESIGNED" ||
                          searchData.filter === "ESIGN_REQUESTED"
                          ? "d-block"
                          : ""
                          }
                          ${searchData.filter === "COMPLETED" ? "d-none" : ""}
                          `}
                      >
                        <Button
                          disabled={row.rekycStatus === "true"}
                          onClick={() => ExportButton(row.userid, row.name)}
                          variant="success"
                        >
                          Export
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p className="no-data-available">No Data Available</p>
              )}
            </Table>
          </Col>
        </Row>
        <Pagination className="px-4">
          {Array(+data?.totalPage ?? 1)
            ?.fill(1)
            ?.map((_, index) => {
              return (
                <Pagination.Item
                  onClick={() =>
                    setSearchData((prev) => ({ ...prev, page: index + 1 }))
                  }
                  key={index + 1}
                  active={index + 1 === searchData.page}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}
        </Pagination>
      </Container>
    </div>
  );
};

export default ClientListing;
