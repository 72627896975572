import React from "react";
import useApi from "../../hooks/useApi";
import Button from "react-bootstrap/Button";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Base64 } from "js-base64";

function Aadhar() {
  const [data, setData] = React.useState({});
  const { loading,  fetchData } = useApi();
  let { clientId } = useParams();

  const openInNewTab = (url) => {
    window.open(`${process.env.REACT_APP_REDIRECT_URL}audit-trail/${clientId}`, "_blank");
  };

  const handleApiCalls = async (url) => {
    const baseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      setData(data);
      return data;
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  React.useEffect(() => {
    handleApiCalls(`export/button/${clientId}`);
  }, []);
  return (
    <div>
      <Loader open={loading} />
      <ToastContainer />
      <Button variant="success" onClick={openInNewTab}>
        Audit Trail PDF
      </Button>
      <div>
        {!loading && data?.aadharXml ? (
          Base64.decode(data?.aadharXml ?? "")
        ) : (
          <div>PDF Not Available</div>
        )}
      </div>
    </div>
  );
}

export { Aadhar };
