import React from "react"
import "./styles.css"
// import XmlComponent from "../../components/xmlRender";

const AddressProof = ({ data }) => {
  return (
    <div className="border border-2">
      {/* <XmlComponent data={data?.aadhar?.aadharXml ?? ""} /> */}
      <table
        style={{
          border: "2px solid black",
          borderCollapse: "collapse",
          pageBreakAfter: "always",
          marginTop: "100px",
        }}
      >
        <tbody>
          <tr>
            <td colSpan="4">
              <br />
              <br />
              <br />
              <b>Proof Of Address</b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" style={{ textAlign: "center", fontSize: "larger" }}>
              <b>Offline Aadhaar verified e-Aadhaar</b>
              <span style={{ fontSize: "smaller" }}>
                This document is generated from zip file obtained from UIDAI
                site with due user consent and authentication
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Document type</td>
            <td colSpan="3" style={{ border: "1px solid black" }}>
              e-Aadhaar generated from Digilocker verified Aadhaar XML
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", width: "15%" }}>
              Generated Date
            </td>
            <td style={{ border: "1px solid black", width: "30%" }}>
              {data?.aadhar?.timeStampGeneratedTimeActual}
            </td>
            <td style={{ border: "1px solid black", width: "30%" }}>
              Download Date
            </td>
            <td style={{ border: "1px solid black", width: "25%" }}>
              {data?.aadhar?.downloadedTimeInternal}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Mask Aadhaar number</td>
            <td colSpan="3" style={{ border: "1px solid black" }}>
              {data?.aadhar?.maskAadharNumber}
            </td>
          </tr>
          <tr>
            <td style={{ width: "30%", border: "1px solid black" }}>Name</td>

            <td colSpan="2" style={{ border: "1px solid black" }}>
              {data?.aadhar?.name}
            </td>
            <td style={{ maxWidth: "150px", overflow: "hidden" }} rowSpan="5">
              <img
                src={`data:image/JPEG;base64, ${data?.aadhar?.aadharProfilePhotoBase64}`}
                width="100px"
                height="100px"
                alt="image not available"
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Date Of Birth</td>
            <td colSpan="2" style={{ border: "1px solid black" }}>
              {data?.aadhar?.dob}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Gender</td>
            <td colSpan="2" style={{ border: "1px solid black" }}>
              {data?.pan?.gender}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>D/o, S/o</td>
            <td colSpan="2" style={{ border: "1px solid black" }}>
              {data?.aadhar?.careOf}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Address</td>
            <td colSpan="2" style={{ border: "1px solid black" }}>
              {data?.aadhar?.aadharAddressDto?.houseNumber}{" "}
              {data?.aadhar?.aadharAddressDto?.street}{" "}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Landmark</td>
            <td style={{ border: "1px solid black" }}>
              {data?.aadhar?.aadharAddressDto?.location}
            </td>
            <td style={{ border: "1px solid black" }}>Locality</td>
            <td style={{ border: "1px solid black" }}>
              {data?.aadhar?.aadharAddressDto?.votingCenter}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>City/ District</td>
            <td colSpan="3" style={{ border: "1px solid black" }}>
              {data?.aadhar?.aadharAddressDto?.district}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black" }}>Pin Code</td>
            <td style={{ border: "1px solid black" }}>
              {data?.aadhar?.aadharAddressDto?.pincode}
            </td>
            <td style={{ border: "1px solid black" }}>State</td>
            <td style={{ border: "1px solid black" }}>
              {" "}
              {data?.aadhar?.aadharAddressDto?.state}{" "}
            </td>
          </tr>
          <tr>
            <td
              colSpan="4"
              style={{ textAlign: "center", fontSize: "smaller" }}
            >
              For Limited Circulation | CONFIDENTIAL
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default AddressProof
