import React, { useState, useEffect } from 'react';

const PdfPreview = ({ base64String }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const decodedData = atob(base64String);
        const blob = new Blob([new Uint8Array(decodedData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error decoding base64 string:', error);
        setError(error.message);
      }
    };

    fetchPdf();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [base64String]);

  return (
    <div style={{ width: '100%', height: '500px' }}>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
          <p>PDF cannot be displayed</p>
        </object>
      )}
    </div>
  );
};

export default PdfPreview;
