import React,{ useState } from 'react'
import Home from '../views/home';
import Logo from "../assest/RYZ.png"
import Finovo from "../assest/finovoLogo.png"
import ReKycHome from "../reKycAdmin/views/home"
import Closure from '../Closure/views/home';
import "./styles.css"
function MainHome() {
  const [toggleState, setToggleState] = useState(1);
  const handleButtonClick = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <div className="flex p-2 bg-secondary" >
        <button onClick={() => handleButtonClick(1)} className={`px-3 py-2  fw-bold mx-3 ${toggleState === 1 ? 'button-selected bg-primary' : 'button-unSelected'}`}>Kyc</button>
        <button onClick={() => handleButtonClick(2)} className={`px-3 py-2  fw-bold mx-3 ${toggleState === 2 ? 'button-selected bg-primary' : 'button-unSelected'}`}>Re-Kyc</button>
        <button onClick={() => handleButtonClick(3)} className={`px-3 py-2  fw-bold mx-3 ${toggleState === 3 ? 'button-selected bg-primary'   : 'button-unSelected'}`}>Closure</button>
      </div>
      {toggleState === 1 ? <Home /> : null}
      {toggleState === 2 ? <ReKycHome /> : null}
      {toggleState === 3 ? <Closure /> : null}
      <div className="d-flex align-items-center justify-content-center m-auto">
        <p>All rights reserved ©2024 Powered by &nbsp;</p>
        <img src={Finovo} width="100px " className='mb-3 ' />
      </div>
    </>
  )
}

export default MainHome