import React, { useState } from "react";
import "./styles.css";
import Slider from "../Slider";
import { Stepper } from "../../components/Stepper";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";

const Verification = () => {
  const { loading, error, postData, fetchData } = useApi();
  const { clientId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [clientData, setClientData] = useState();

  async function getClientDetails() {
    const data = await fetchData(
      `admin-ekyc/user-data/${clientId}`,
      {},
      process.env.REACT_APP_ADMIN_BASE_URL
    );
    setClientData(data);
  }

  React.useEffect(() => {
    getClientDetails().catch((err) => console.error(err));
  }, []);

  const handleApiCalls = async (url) => {
    try {
      const data = await fetchData(url, {}, process.env.REACT_APP_ADMIN_BASE_URL);
      toast.success("E-sign initiated successfully");
    } catch (e) {
      toast.error(e.message ?? "Something went wrong");
    }
    setShowModal(false);
  };
  const toggle = () => {
    
    setShowModal(true);
  }
  const toggleNot = () =>{
    setShowModal(false);
  }

  return (
    <main className="bg-secondary">
      <Loader open={loading} />
      <ToastContainer />
      <div className="verification-wrapper d-flex bg-light">
        
        <div className="bank-leftSide px-2 py-1 ">
          <Stepper clientData={clientData} />
        </div>
        <div className="bank-rightSide flex flex-col">
          <div className="">
            <Slider clientData={clientData} />
          </div>
          <div className="d-flex justify-content-center mt-2 border-2 border-dark">
            {/* <Button
              onClick={() => handleApiCalls(`ekyc/user/leegality/notification/${clientId}`)}
              className="self-end"
              variant="outline-info"
              size="sm"
            >
              E-sign
            </Button> */}
            <Button
            className={`${clientData?.userDetail?.kycStatus === "THANK_YOU" ?"d-block":"d-none"} d-flex justify-content-center `}
              onClick={toggle}>Final Approve</Button>
            <div>
              {showModal && (
                <>
                  <div className="modal-wrapper "></div>
                  <div className="modall-container bg-white p-10 rounded-xl">
                    <p className="fs-5 fw-bold px-5 text-start" onClick={toggleNot}>X</p>
                    <div className="d-flex justify-content-center align-items-center h-75">
                    <Button
                      onClick={() =>
                        handleApiCalls(
                          `ekyc/user/leegality/notification/${clientId}`
                        )
                      }
                      className="self-end m-auto"
                      variant="outline-info"
                      size="lg"
                    >
                      E-sign
                    </Button>
                    </div>
                    
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Verification;
