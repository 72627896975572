import React, { useState , useEffect} from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import Button from 'react-bootstrap/Button';

const BankProof = ({ data ,updateRejectData }) => {
  const { loading, error, postData, fetchData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");

  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const handleUploadData = async () => {
    if (imageData) {
      try {

        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            bankProof: imageData,
          }
        );

        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }

  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);

  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  // const handleApiCall = async () => {
  //   try {
  //     if (remark?.length > 10) {
  //       const res = await postData(
  //         `reject/upload/${data?.userDetail?.phoneNumber}`,
  //         {
  //           BANK_PROOF: remark,
  //         }
  //       );
  //     }
  //     toast.success("Rejected successfully");
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //   }
  // };
  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>BANK PROOF</b>${remark}`
    updateRejectData({ BANK_PROOF: newRem });
    setRejectButtonDisabled(true);
    toast.success("Reject Added")
  }


  useEffect(() => {
    const storedRemark = localStorage.getItem("bankProof");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("bankProof", remark);
    // console.log("========>",data.rekycUserRequestedDetails.bankProof);
  }, [remark]);

  // const signatureValue = data?.rejectionList.find(item => item.startsWith("BANK_PROOF:")) || "";
  const signatureValue = (data?.rejectionList || []).find(item => item.startsWith("BANK_PROOF:")) || "";
  const inputValue = signatureValue.replace("BANK_PROOF:", "") ? signatureValue.replace("BANK_PROOF:", "") : remark;
 
  return (
    <div className="border border-2">
      <Loader open={loading} />
      <ToastContainer />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label for="floatingInputGroup1">
            <b
              className={`${
                Boolean(data?.rejectedDocumentDetails?.BANK_PROOF)
                  ? "error-text"
                  : ""
              }`}
            >
              Reject Remark
            </b>
          </label>
          <input
            // disabled={Boolean(data?.rejectedDocumentDetails?.BANK_PROOF)}
            value={inputValue}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
        <Button onClick={handleConsole} disabled={inputValue == signatureValue.replace("BANK_PROOF:", "")}>Reject</Button>{' '}
        </div>
        {/* <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Bank proof (only png.jpg.pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
            type="file"
            name="financialProof"
            onChange={onChange}
            accept=".pdf"
          />
          {imageData && (
            <>
              <div className="w-1/4 h-50 p-4">
                <img
                  className="w-full"
                  src={imageData}
                  alt="bank validation proof"
                />
              </div>
              <button
                variant="outlined"
                color="error"
                onClick={() => setImageData("")}
              >
                Cancel
              </button>
            </>
          )}
          <button onClick={handleUploadData} className="btn btn-danger m-auto ">
            Upload
          </button>
        </div> */}
      </div>
      <div className="document-container">
        {/* <div className="panImg-container"> */}
          {/* <img
            src={data?.bankDetails?.bankValidationProof}
            alt=""
            width="100%"
            height="100%"
          /> */}
        {/* {data?.rekycUserRequestedDetails?.bankProof ? (
          // <PdfPreview
          //   base64String={data?.rekycUserRequestedDetails?.bankProof ?? ""}
          // />
          <img src={`data:image/png;base64,${data?.rekycUserRequestedDetails?.bankProof}`} alt="" width="70%" />
        ) : (
          ""
        )} */}
         {data?.rekycUserRequestedDetails?.bankProof ? (
           data.rekycUserRequestedDetails.bankProof.startsWith("data:image") ? (
             <img src={data.rekycUserRequestedDetails.bankProof} alt="" />
            ) : (
              <PdfPreview base64String={data.rekycUserRequestedDetails.bankProof} />
            )
          ) : ""}
          {/* </div> */}
      </div>
    </div>
  );
};

export default BankProof;
