import React from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import { useParams } from "react-router-dom";

function ESigned() {
  const [data, setData] = React.useState({});
  const { loading, error, postData, fetchData } = useApi();
  let { clientId } = useParams();

  const handleApiCalls = async (url) => {
    const baseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      setData(data);
      return data;
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  React.useEffect(() => {
    handleApiCalls(`export/button/${clientId}`);
  }, []);
  return (
    <div>
      <Loader open={loading} />
      <ToastContainer />
      <div>
        {!loading && data.esignedPdf ? (
          <PdfPreview base64String={data?.esignedPdf} />
        ) : (
          <div>PDF Not Available</div>
        )}
      </div>
    </div>
  );
}

export { ESigned };
