import React, { useState, useEffect,useRef } from "react";
import Button from 'react-bootstrap/Button';
import PdfPreview from "../../components/pdfViewer";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';


const Nominee1 = ({ data, updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (phoneNumber) => {
    if (selectedFile) {
      const authToken = sessionStorage.getItem("authToken");

      // Create a FileReader to read the file
      const reader = new FileReader();

      // Event listener for when the file has been read
      reader.onload = async () => {
        // Get the base64 encoded string
        const base64EncodedFile = reader.result;

        // Create the payload as a JSON object
        const payload = {
          nomineeOne: base64EncodedFile
        };

        try {
          // Make the POST request with JSON payload
          const response = await axios.post(`https://kyc.finovo.tech:5556/api/feature/additional/upload/nominee/${phoneNumber}`, payload, {
            headers: {
              'Authorization': authToken,
              'Content-Type': 'application/json'
            }
          });
          toast.success("Upload Successfully")
          fileInputRef.current.value = '';
          // Handle response, e.g., display a success message
          console.log('File uploaded successfully:', response.data);
        } catch (error) {
          // Handle error, e.g., display an error message
          toast.error('Error uploading file:', error);
        }
      };

      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    } else {
      console.error('No file selected');
    }
  };



  const handleConsole = () => {
    const newRem = remark === "" ? "" : `<b>FIRST NOMINEE PROOF</b>${remark}`
    updateRejectData({ NOMINEE_ONE: newRem });
    setRejectButtonDisabled(true);
    toast.success("Reject Added")
  }


  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee1");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee1", remark);
  }, [remark]);


  return (
    <div className="border border-2">
      <ToastContainer />
      <div className="head-container">

        <div className="input-file p-2 text-start gap-2 border">
          <label htmlFor="floatingInputGroup1">
            <b>
              Nominee proof (only png, jpg, pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
          ref={fileInputRef}
            type="file" id="floatingInputGroup1" accept=".png,.jpg,.pdf" onChange={handleFileChange} />
          <button className="btn btn-info m-auto" onClick={() =>
            handleUpload(data.userDetail.phoneNumber)
          }>Upload</button>
        </div>
      </div>
      <div className="document-container">
        <div className="panImg-container">
          {data?.nomineeDetails[0]?.proofOfAddress ? (
            data?.nomineeDetails[0]?.proofOfAddress.startsWith("data:image") ? (
              <img src={data?.nomineeDetails[0]?.proofOfAddress} alt="" />
            ) : (
              <PdfPreview base64String={data?.nomineeDetails[0]?.proofOfAddress} />
            )
          ) : ""}
          {/* {data?.nomineeDetails[0]?.proofOfAddress ? (
            // If proofOfAddress exists, render PdfPreview
            <img
              src={data?.nomineeDetails[0]?.proofOfAddress}
              alt=""
              width="100%"
              height="100%"
            />
          ) : (
            // If proofOfAddress doesn't exist, render an image or a message
            data?.nomineeDetails[0]?.proofOfAddress ? (
              <PdfPreview base64String={data?.nomineeDetails[0]?.proofOfAddress} />
              // If nomineeDetails exist and have length, render image

            ) : (
              // If nomineeDetails is empty, render a message
              ""
            )
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Nominee1;