import React from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import { useParams } from "react-router-dom";

function AuditTrail() {
  const [data, setData] = React.useState({});
  const { loading,  fetchData } = useApi();
  let { clientId } = useParams();

  const openInNewTab = () => {
    const newWindow = window.open(
      `${process.env.REACT_APP_REDIRECT_URL}esigned/${clientId}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleApiCalls = async (url) => {
    const baseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      setData(data);
      return data;
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  React.useEffect(() => {
    handleApiCalls(`export/button/${clientId}`);
  }, []);
  return (
    <div>
      <Loader open={loading} />
      <ToastContainer />
      <div>
        <Button variant="success" onClick={openInNewTab}>
          E Signed PDF
        </Button>
        {!loading && data?.auditTrailPdf ? (
          <PdfPreview base64String={data?.auditTrailPdf} />
        ) : (
          <div>PDF Not Available</div>
        )}
      </div>
    </div>
  );
}

export { AuditTrail };
